/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'chat-square-quote-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M0 2a2 2 0 012-2h12a2 2 0 012 2v8a2 2 0 01-2 2h-2.5a1 1 0 00-.8.4l-1.9 2.533a1 1 0 01-1.6 0L5.3 12.4a1 1 0 00-.8-.4H2a2 2 0 01-2-2zm7.194 2.766a1.7 1.7 0 00-.227-.272 1.5 1.5 0 00-.469-.324l-.008-.004A1.8 1.8 0 005.734 4C4.776 4 4 4.746 4 5.667c0 .92.776 1.666 1.734 1.666.343 0 .662-.095.931-.26-.137.389-.39.804-.81 1.22a.405.405 0 00.011.59c.173.16.447.155.614-.01 1.334-1.329 1.37-2.758.941-3.706a2.5 2.5 0 00-.227-.4zM11 7.073c-.136.389-.39.804-.81 1.22a.405.405 0 00.012.59c.172.16.446.155.613-.01 1.334-1.329 1.37-2.758.942-3.706a2.5 2.5 0 00-.228-.4 1.7 1.7 0 00-.227-.273 1.5 1.5 0 00-.469-.324l-.008-.004A1.8 1.8 0 0010.07 4c-.957 0-1.734.746-1.734 1.667 0 .92.777 1.666 1.734 1.666.343 0 .662-.095.931-.26z"/>',
    },
});
